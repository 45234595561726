#dmfbody  {
   background-color: #3ba2c9;
}

form, #enterDMF {
   width: 100%;
   padding: 20px;
   border-radius: 6px;
   background: #fff;
   box-shadow: 0 0 20px 0 #a82877;
}


input, PhoneInput{
   display: block;
   /*box-sizing: inherit !important ;*/
   /*width: 100% !important;*/
   /*border-radius: 30px !important;*/
   /*border: 1px solid rgb(0, 0, 0);*/
   /*padding: 10px 15px !important;*/
   margin-bottom: 10px !important;
   font-size: 18px !important;

}

HTML CSSResult Skip Results Iframe
EDIT ON
html, body, #loading {
   height: 1000px;
   width: 100%;
   margin: 0;
   padding: 0;
   font-size: 100%;
   background: #ffffff;
   text-align: center;
}

h1 {
   margin: 0;
   padding: 0;
   font-family: ‘Arial Narrow’, sans-serif;
   font-weight: 800;
   font-size: large;
   color: #f24405;

}

/*span {*/
/*   position: relative;*/
/*   display: inline-block;*/
/*   text-transform: uppercase;*/
/*   opacity: 0;*/
/*   transform: rotateX(-90deg);*/
/*}*/

.let1 {
   animation: drop 1.2s ease-in-out infinite;
   animation-delay: 1.2s;
}

.let2 {
   animation: drop 1.2s ease-in-out infinite;
   animation-delay: 1.3s;
}

.let3 {
   animation: drop 1.2s ease-in-out infinite;
   animation-delay: 1.4s;
}

.let4 {
   animation: drop 1.2s ease-in-out infinite;
   animation-delay: 1.5s;

}

.let5 {
   animation: drop 1.2s ease-in-out infinite;
   animation-delay: 1.6s;
}

.let6 {
   animation: drop 1.2s ease-in-out infinite;
   animation-delay: 1.7s;
}

.let7 {
   animation: drop 1.2s ease-in-out infinite;
   animation-delay: 1.8s;
}

@keyframes drop {
   10% {
      opacity: 0.5;
   }
   20% {
      opacity: 1;
      top: 3.78em;
      transform: rotateX(-360deg);
   }
   80% {
      opacity: 1;
      top: 3.78em;
      transform: rotateX(-360deg);
   }
   90% {
      opacity: 0.5;
   }
   100% {
      opacity: 0;
      top: 6.94em
   }
}

